import React from 'react';

import './index.sass';


class FAQSection extends React.PureComponent {

  state = {
    activeTab: null,
  };

  toggleFAQWrapper = (wrapperName) => {
    const { activeTab } = this.state;

    this.setState({ activeTab: wrapperName }, () => {

      !activeTab && this[wrapperName].classList.add('FAQSection__question-wrapper--open');

      if (activeTab) {

        if (activeTab === wrapperName) {
          this[activeTab].classList.toggle('FAQSection__question-wrapper--open');
          this[activeTab].classList.toggle('FAQSection__question-wrapper--close');
        } else {
          this[activeTab].classList.remove('FAQSection__question-wrapper--open');
          this[activeTab].classList.add('FAQSection__question-wrapper--close');
          this[wrapperName].classList.remove('FAQSection__question-wrapper--close');
          this[wrapperName].classList.add('FAQSection__question-wrapper--open');
        }
      }
    });
  };

  render() {
    const { indentation, isRequestFromPrerender, richSnippetQuestions, title, hidden } = this.props;

    if (!hidden) {
      return (
        <div className={`FAQSection container${ indentation === 0 ? ' no-padding' : '' }`} ref={el => this.faqWrapper = el} id="faq">
          <h2 className="FAQSection__title">
            { title }
          </h2>
          { richSnippetQuestions.map((questionBlock, index) => {
            return (
              <div key={index}
                   className={`FAQSection__question-wrapper${ isRequestFromPrerender ? ' FAQSection__question-wrapper--open' : '' }`}
                   ref={el => this[`faqAnswer${index}`] = el}>
                <div className="FAQSection__question" onClick={() => this.toggleFAQWrapper(`faqAnswer${index}`)}>
                  { questionBlock.question }
                </div>
                <div className="FAQSection__answer">
                  <div dangerouslySetInnerHTML={{ __html: questionBlock.answer }} />
                </div>
              </div>
            )})
          }
        </div>
      )
    } else {
      return null;
    }
  }
}

export default FAQSection;
